import csLocale from 'date-fns/locale/cs/index.js';
import enLocale from 'date-fns/locale/en-GB/index.js';
import skLocale from 'date-fns/locale/sk/index.js';

export interface Duration {
  hours: number;
  minutes: number;
  seconds: number;
  days: number;
}

export const dateFnsLocales = {
  sk: skLocale,
  cs: csLocale,
  en: enLocale,
};

export function parseDuration(value: string): number {
  // Return number of seconds
  if (value.includes(':')) {
    const parts = value.split(':');
    return parts.reduce((acc, cur) => 60 * acc + parseInt(cur, 10), 0) * 60;
  } else {
    // Default is hour
    return Math.round(parseFloat(value.replace(',', '.')) * 3600);
  }
}

export function secondsToHours(value: number): string {
  return (value / 3600).toFixed(2);
}

export function calculateInterval(endAt: string | Date, startAt?: string | Date): Duration {
  const start = new Date(startAt).getTime();
  const end = new Date(endAt).getTime();
  if (start > end) {
    return { hours: 0, minutes: 0, seconds: 0, days: 0 };
  }
  const duration = (Math.max(start, end) - Math.min(start, end)) / 1000;
  return {
    seconds: Math.floor(duration % 60),
    minutes: Math.floor(duration / 60) % 60,
    hours: Math.floor(duration / 3600) % 24,
    days: Math.floor(duration / (24 * 3600)),
  };
}

export function isValidDate(date: Date | null) {
  return date && !isNaN(date.getTime());
}
